import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "../shared/utils/dates";
import Pagination from "../shared/pagination";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { getUserBets } from "originals/originals-games-api";

const UserPlinkoRewiredBets = ({ match, ...rest }) => {
  const [data, setData] = useState({ bets: [], total_pages: 0 });
  const [activatedOldHistory, setActivatedOldHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const data = await (activatedOldHistory
          ? window.api.get(
              `/admin/users/${match.params.id}/plinko_bets?page=${page}`
            )
          : getUserBets(match.params.id, {
              page,
              type: "plinko",
            }));
        setData({ total_pages: data?.pages || data.total_pages, bets: data.records });
      } catch (err) {
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  }, [page, match.params.id, activatedOldHistory]);

  const renderTableRows = ({
    game,
    wallet_params,
    multiplier,
    id,
    round_id,
    remoteRoundId,
    winnings,
    profit,
    amount,
    currency_type,
    bet_placed_at,
    bet_settled_at,
  }) => {
    const isFreeBet = wallet_params?.free_bet;
    const payout = winnings > 0 ? profit : -1 * amount;
    const winningsValue = parseFloat(winnings > 0 ? winnings : -amount).toFixed(
      2
    );
    const roundId = activatedOldHistory ? round_id : remoteRoundId;

    return (
      <Tr key={roundId}>
        <Td>
          {isFreeBet ? (
            id
          ) : (
            <Link to={`/casino/game-provider-rounds/${roundId}`}>
              {roundId}
            </Link>
          )}
        </Td>
        <Td>{format.withSeconds(bet_placed_at)}</Td>
        <Td>{format.withSeconds(bet_settled_at)}</Td>
        <Td>{`${amount} ${currency_type}`}</Td>
        <Td className={+profit >= 0 ? "good" : ""}>
          {winningsValue} {wallet_params?.currency}
        </Td>
        <Td>{(+multiplier || +game.multiplier).toFixed(2)}</Td>
        <Td>{game?.risk || ''}</Td>
        <Td>{game?.rows || ''}</Td>
        <Td>{game?.roll || ''}</Td>
        {!activatedOldHistory && <Td>{isFreeBet ? "yes" : "no"}</Td>}
        <Td className={profit >= 0 ? "good" : "bad"}>
          {(+payout).toFixed(2)} {currency_type}
        </Td>
      </Tr>
    );
  };

  return (
    <>
      <span
        role="button"
        tabIndex={0}
        style={{ color: "#97a8ff", cursor: "pointer", margin: "10px" }}
        onClick={() => setActivatedOldHistory((prev) => !prev)}
      >
        {activatedOldHistory ? "Plinko History" : "Old Plinko History"}
      </span>
      <div style={{ position: "relative", "min-height": "500px" }}>
        {isLoading && (
          <div className={"loading-overlay"}>
            <h2>Loading, please wait ...</h2>
            <div className="loader" />
          </div>
        )}
        <Table>
          <Thead>
            <Tr>
              <Th>Round ID</Th>
              <Th>Bet Placed At</Th>
              <Th>Bet Settled At</Th>
              <Th>Amount</Th>
              <Th>Winnings</Th>
              <Th>Multiplier Point</Th>
              <Th>Risk</Th>
              <Th>Rows</Th>
              <Th>Roll</Th>
              {!activatedOldHistory && <Th>Free Bet</Th>}
              <Th>Profit</Th>
            </Tr>
          </Thead>
          <Tbody>{data.bets.map(renderTableRows)}</Tbody>
        </Table>

        <Pagination total_pages={data.total_pages} update={setPage} />
      </div>
    </>
  );
};

export default UserPlinkoRewiredBets;